<template>
  <b-carousel id="carouselFront" controls :interval="50000" fade>
    <b-carousel-slide v-for="slider in sliders" :key="slider.name"
      :caption="slider.title">
      <a :href="slider.url" slot="img">
        <img slot="img" class="w-100" :src="slider.image" :srcset="`${slider.image_tiny} 600w, ${slider.image_small} 1024w, ${slider.image} 2000w`">
      </a>
    </b-carousel-slide>
  </b-carousel>
</template>
<script>
export default {
  props: {
    sliders: {
      required: true,
      type: Array
    },
    lang: {
      required: true,
      type: String,
      default: 'en'
    }
  }
}
</script>
